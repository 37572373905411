
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filter',
    pure: false
})

export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) { return []; }
        if (!searchText) { return items; }
        searchText = searchText.toLowerCase();
        return items.filter((item: any) => this.applyFilter(item, searchText));
    }

    applyFilter(item: any, searchText: string): boolean {
        let flag = false;

        if (!flag && item['DealerName'] && item['DealerName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['DealerSEName'] && item['DealerSEName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['ProductRangeName'] && item['ProductRangeName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['AccountTypeName'] && item['AccountTypeName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }        
        if (!flag && item['TargetYear'] && item['TargetYear'].toString().toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['CompanyName'] && item['CompanyName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }

        if (!flag && item['AccountName'] && item['AccountName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['LeadName'] && item['LeadName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['VisitType'] && item['VisitType'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['PotentialType'] && item['PotentialType'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['LeadTypeName'] && item['LeadTypeName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['PersonName'] && item['PersonName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['EmailID'] && item['EmailID'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['OpportunityName'] && item['OpportunityName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['SalesPhaseName'] && item['SalesPhaseName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['DistrictName'] && item['DistrictName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['StateName'] && item['StateName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['FirstName'] && item['FirstName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['LastName'] && item['LastName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['MobileNumber'] && item['MobileNumber'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['OpportunityStatusName'] && item['OpportunityStatusName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        // if (!flag && item['WinningChanceName'] && item['WinningChanceName'].toLowerCase().indexOf(searchText) > -1) {
        //     flag = true;
        // }
        if (!flag && item['VisitTypeName'] && item['VisitTypeName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['ApplicationName'] && item['ApplicationName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['SegmentName'] && item['SegmentName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['DistrictName'] && item['DistrictName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['StateName'] && item['StateName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['Designation'] && item['Designation'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['ProductModelName'] && item['ProductModelName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['ACUserName'] && item['ACUserName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['Zone'] && item['Zone'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['ZoneName'] && item['ZoneName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }        
        if (!flag && item['GSTNNumber'] && item['GSTNNumber'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        if (!flag && item['LossingReasonsName'] && item['LossingReasonsName'].toLowerCase().indexOf(searchText) > -1) {
            flag = true;
        }
        return flag;
    }
}
