import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/model/master/user/user';
import { HeaderService } from 'src/app/service/transaction/header/header.service';
import { Header } from './Header';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  header = new Header();
  data: any;
  DealerName : any;
  DealerSEName : any;
  VisitCount : number;
  MonthName : any;
  MonthYear : any;
  currentUser: User;
  constructor( 
    private router: Router,
    private headerService: HeaderService,
    ) { 

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
   }

  ngOnInit() {
    const me = this;
    me.headerService.search(me.currentUser.UserID).subscribe(data => {
      //console.log(data.Response);
      if(data.Response)
      {
        me.data = data.Response;
        me.VisitCount = me.data.VisitCount;
        me.DealerName = me.data.DealerName;
        me.DealerSEName = me.data.DealerSEName;
        me.MonthName = me.data.MonthName;
        me.MonthYear = me.data.MonthYear;
      }
      
    });
    // console.log(me.currentUser);
    
    me.headerService.SetTopMostCount(me.currentUser.UserID, me.currentUser.RoleName).subscribe(data => {
      me.data = data.Response;
      // me.VisitCount = me.data.VisitCount;
      // me.DealerName = me.data.DealerName;
      // me.DealerSEName = me.data.DealerSEName;
      // me.MonthName = me.data.MonthName;
      // me.MonthYear = me.data.MonthYear;
      // console.log(data.Response);
    });
  }

  logout() {
    localStorage.setItem('isLoggedIn','false');
    localStorage.removeItem('currentUser');
    this.router.navigate(['/', 'login']);
  }

  

  changepassword(){
    this.router.navigate(['/', 'change_password']);
  }

}
