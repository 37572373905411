import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ForgotPass } from './forgotPass';
import { LoginService } from '../service/master/login/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  objForgot = new ForgotPass();
  forgotFailed = false;
  responseMessage: any;
  message: string;
  constructor(
    private router: Router,
    private loginService:LoginService,
    private toastr: ToastrService
    ) { }

  ngOnInit() {
  }

//   submit() {
//     const me = this;
//     if (me.objForgot && me.objForgot.UserEmail) {
//       me.loginService.forgotPassword(me.objForgot.UserEmail).subscribe(data => {
//         me.responseMessage = data;
//         me.message = this.responseMessage.Message;
//         if (me.responseMessage.IsSuccess) {
//           me.toastr.success(me.message + ' updated  successfully');
//         } else {
//           me.toastr.error('Something went wrong');
//         }
//   }

// }


submit() {
    const me = this;
    if (me.objForgot && me.objForgot.UserEmail) {
      me.loginService.forgotPassword(me.objForgot.UserEmail).subscribe(data => {
        me.responseMessage = data;
        me.message = me.responseMessage.Message;
        if (me.responseMessage.IsSuccess) {
          me.toastr.success(me.message + ' Your password has been reset successfully! <br > Your new password has been sent to your primary email address.');
        } else {
          me.toastr.error('Something went wrong');
        }
      });
    }
  }
}