import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { IndexComponent } from './pages/index/index.component';
import { ShellComponent } from './shell/shell.component';
import { LoginComponent } from './login/login.component';
import { AccountComponent } from './pages/account/account.component';
import { MasterComponent } from './pages/master/master.component';
import { VisitComponent } from './pages/visit/visit.component';
import { ContactComponent } from './pages/contact/contact.component';
import { QuoteupdateComponent } from './pages/quoteupdate/quoteupdate.component';
import { SegmentComponent } from './pages/segment/segment.component';
import { RoleComponent } from './pages/role/role.component';
import { ZoneComponent } from './pages/zone/zone.component';
import { DealerComponent } from './pages/dealer/dealer.component';
import { AccountTypeComponent } from './pages/account-type/account-type.component';
import { VisitTypeComponent } from './pages/visit-type/visit-type.component';
import { ApplicationComponent } from './pages/application/application.component';
import { LeadtypeComponent } from './pages/leadtype/leadtype.component';
import { ProductrangeComponent } from './pages/productrange/productrange.component';
import { CompetitiontechComponent } from './pages/competitiontech/competitiontech.component';
import { CompetionmakeComponent } from './pages/competionmake/competionmake.component';
import { ProductmodelComponent } from './pages/productmodel/productmodel.component';
import { SalesphaseComponent } from './pages/salesphase/salesphase.component';
import { ChanceswinningComponent } from './pages/chanceswinning/chanceswinning.component';
import { OppcremodeComponent } from './pages/oppcremode/oppcremode.component';
import { OppStatusComponent } from './pages/opp-status/opp-status.component';
import { ReasonWinningComponent } from './pages/reason-winning/reason-winning.component';
import { ReasonLosingComponent } from './pages/reason-losing/reason-losing.component';
import { OrderWonComponent } from './pages/order-won/order-won.component';
import { DealeradminComponent } from './pages/dealeradmin/dealeradmin.component';
import { QualifiedleadComponent } from './pages/qualifiedlead/qualifiedlead.component';
import { UserComponent } from './pages/user/user.component';
import { DealeruserComponent } from './pages/dealeruser/dealeruser.component';
import { ReportComponent } from './pages/report/report.component';
import { LeadComponent } from './pages/lead/lead.component';
import { DealertargetComponent } from './pages/dealertarget/dealertarget.component';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AccConReportComponent } from './pages/acc-con-report/acc-con-report.component';
import { LeadreportComponent } from './pages/leadreport/leadreport.component';
import { VisitreportComponent } from './pages/visitreport/visitreport.component';
import { QuotationReportComponent } from './pages/quotation-report/quotation-report.component';
import { DealerSePerComponent } from './pages/dealer-se-per/dealer-se-per.component';
import { DealerComPerformanceComponent } from './pages/dealer-com-performance/dealer-com-performance.component';
import { DealerPerformanceAnalysisComponent } from './pages/dealer-performance-analysis/dealer-performance-analysis.component';
import { ContactReportComponent } from './pages/contact-report/contact-report.component';
import { DealerQuanTarComponent } from './pages/dealer-quan-tar/dealer-quan-tar.component';
import { CompDashboardComponent } from './pages/comp-dashboard/comp-dashboard.component';
import { AuthGuard } from './authguard/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ACEnquiriesComponent } from './pages/acenquiries/acenquiries.component';
import { NumberofAppgraphComponent } from './pages/numberof-appgraph/numberof-appgraph.component';
import { HelpdeskComponent } from './pages/helpdesk/helpdesk.component';
import { AnnouncementComponent } from './pages/announcement/announcement.component';
import { TopuservisitsComponent } from './pages/topuservisits/topuservisits.component';
import { ViewvirtualcallhistoryComponent } from './pages/viewvirtualcallhistory/viewvirtualcallhistory.component';
import { MassmailerComponent } from './pages/massmailer/massmailer.component';
import { MassMailListComponent } from './pages/mass-mail-list/mass-mail-list.component';
import { MassMailViewComponent } from './pages/mass-mail-view/mass-mail-view.component';
import { ScorecardOpportunityComponent } from './pages/scorecard-opportunity/scorecard-opportunity.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot_password', component: ForgotPasswordComponent },
  {
    path: '', component: ShellComponent, canActivate: [AuthGuard],
    children: [
      { path: 'index', component: IndexComponent },
      { path: 'account', component: AccountComponent },
      { path: 'master', component: MasterComponent },
      { path: 'visit', component: VisitComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'quote', component: QuoteupdateComponent },
      { path: 'segment', component: SegmentComponent },
      { path: 'role', component: RoleComponent },
      { path: 'zone', component: ZoneComponent },
      { path: 'dealer', component: DealerComponent },
      { path: 'account-type', component: AccountTypeComponent },
      { path: 'visit-type', component: VisitTypeComponent },
      { path: 'application', component: ApplicationComponent },
      { path: 'leadtype', component: LeadtypeComponent },
      { path: 'productrange', component: ProductrangeComponent },
      { path: 'competitiontech', component: CompetitiontechComponent },
      { path: 'competitionmake', component: CompetionmakeComponent },
      { path: 'productmodel', component: ProductmodelComponent },
      { path: 'salesphase', component: SalesphaseComponent },
      { path: 'chanceswinning', component: ChanceswinningComponent },
      { path: 'opp_create_mode', component: OppcremodeComponent },
      { path: 'opp_status', component: OppStatusComponent },
      { path: 'reason_winning', component: ReasonWinningComponent },
      { path: 'reason_losing', component: ReasonLosingComponent },
      { path: 'order_won', component: OrderWonComponent },
      { path: 'dealer_admin', component: DealeradminComponent },
      { path: 'dealertarget', component: DealertargetComponent },
      { path: 'qualifiedlead', component: QualifiedleadComponent },
      { path: 'user', component: UserComponent },
      { path: 'dealeruser', component: DealeruserComponent },
      { path: 'report', component: ReportComponent },
      { path: 'lead', component: LeadComponent },
      { path: 'account-con-report', component: AccConReportComponent },
      { path: 'contact-report', component: ContactReportComponent },
      { path: 'leadreport', component: LeadreportComponent },
      { path: 'visitreport', component: VisitreportComponent },
      { path: 'quotationreport', component: QuotationReportComponent },
      { path: 'dealer_se_per', component: DealerSePerComponent },
      { path: 'dealer_com_performance', component: DealerComPerformanceComponent },
      { path: 'dealer-performance-analysis', component: DealerPerformanceAnalysisComponent },
      { path: 'dealer_quan_tar', component: DealerQuanTarComponent },
      { path: 'com_dashboard', component: CompDashboardComponent },
      { path: 'change_password', component: ChangePasswordComponent },
      { path: 'acenquiries', component: ACEnquiriesComponent },
      { path: 'NumberofAppgraph', component: NumberofAppgraphComponent },
      { path: 'helpdesk', component: HelpdeskComponent },
      { path: 'announcement', component: AnnouncementComponent },
      { path: 'topuservisits', component: TopuservisitsComponent },
      { path: 'viewvirtualcallhistory', component: ViewvirtualcallhistoryComponent },
      { path: 'massmailer', component: MassmailerComponent },
      { path: 'massmaillist', component: MassMailListComponent },
      { path: 'massmailview', component: MassMailViewComponent },
      { path: 'scorecard_opportunity', component: ScorecardOpportunityComponent}
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
export const routingComponents = [MassMailViewComponent, MassMailListComponent, MassmailerComponent,ViewvirtualcallhistoryComponent, TopuservisitsComponent, AnnouncementComponent, HelpdeskComponent, NumberofAppgraphComponent, ACEnquiriesComponent, ChangePasswordComponent, ForgotPasswordComponent, CompDashboardComponent, DealerQuanTarComponent, ContactReportComponent, DealerPerformanceAnalysisComponent, DealerComPerformanceComponent, DashboardComponent, LeadComponent, ReportComponent, DealeruserComponent, UserComponent, QualifiedleadComponent, DealeradminComponent, OrderWonComponent, ReasonLosingComponent, ReasonWinningComponent, OppStatusComponent, OppcremodeComponent, ChanceswinningComponent, SalesphaseComponent, ProductmodelComponent, CompetionmakeComponent, CompetitiontechComponent, ProductrangeComponent, LeadtypeComponent, ApplicationComponent, VisitTypeComponent, AccountTypeComponent, MasterComponent, AccountComponent, HeaderComponent, FooterComponent, SidebarComponent, IndexComponent, ShellComponent, LoginComponent, VisitComponent, ContactComponent, QuoteupdateComponent, SegmentComponent, RoleComponent, ZoneComponent, DealerComponent, DealertargetComponent, AccConReportComponent, LeadreportComponent, VisitreportComponent, QuotationReportComponent, DealerSePerComponent]


